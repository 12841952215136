import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import parseContent from "src/utils/parseContent"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Hero from "src/components/Hero"
import Container from "src/components/Container"
import SocialMediaSharing from "src/components/SocialMediaSharing"
import {
  ArticleContent,
  TextColumn,
  SharingColumn,
  SideColumn,
} from "src/templates/article"
import { SecondaryTitle } from "src/components/Typography"
import { pageNormalizer } from "src/utils/contentNormalizers"
import { get } from "lodash"

export const query = graphql`
  query jobByIdQuery($wordpress_id: Int) {
    wordpressWpJob(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      path
      date(formatString: "DD MMMM YYYY")
      featured_media {
        ...MediaFragment
      }
    }
  }
`

export default props => {
  const { wordpressWpJob, site } = props.data

  const { title, content, path, featured_media } = pageNormalizer(
    wordpressWpJob
  )

  const parsedContent = parseContent(content)

  return (
    <>
      <SEO title={title} image={featured_media} />
      <Ad type="page/before_header" />
      <PageWrapper>
        <Header />
        <Hero title={title} image={featured_media} />
        <Container>
          <Content>
            <TextColumn>
              <Title>{title}</Title>
              {parsedContent}
            </TextColumn>
            <SharingColumn>
              <SocialMediaSharing title={title} path={path} />
            </SharingColumn>
            <SideColumn>
              <Ad type="page/sidebar" />
            </SideColumn>
          </Content>
        </Container>
      </PageWrapper>
      <Ad type="page/before_footer" />
    </>
  )
}

const Content = styled(ArticleContent)`
  margin-bottom: 130px;
`

const Title = styled(SecondaryTitle)`
  margin-bottom: 26px;
`
