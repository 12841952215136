import React, { useState } from "react"
import styled from "styled-components"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import SlideshowHero from "src/components/SlideshowHero"
import SectionStoryGrid from "src/components/SectionStoryGrid"
import SectionSubscribe from "src/components/SectionSubscribe"
import SectionSlider from "src/components/SectionSlider"
import { articleNormalizer } from "src/utils/contentNormalizers"
import { graphql } from "gatsby"
export const query = graphql`
  query HompepageGridQuery {
    wordpressAcfOptions {
      options {
        home_grid_1_show
        home_grid_1 {
          grid_article {
            ... on wordpress__POST {
              ...ArticleFragment
            }
          }
        }
        home_grid_2_show
        home_grid_2 {
          grid_article {
            ... on wordpress__POST {
              ...ArticleFragment
            }
          }
        }
        home_grid_3_show
        home_grid_3 {
          grid_article {
            ... on wordpress__POST {
              ...ArticleFragment
            }
          }
        }
        additional_home_grids {
            home_grid { 
              grid_article {
                ... on wordpress__POST {
                  ...ArticleFragment
                }
              }
            }
        }
      }
    }
  }
`
export default ({ data }) => {
  const {
    wordpressAcfOptions: {
      options: {
        home_grid_1,
        home_grid_2,
        home_grid_3,
        home_grid_1_show,
        home_grid_2_show,
        home_grid_3_show,
        additional_home_grids
      },
    },
  } = data
  const [headerDark, setHeaderDark] = useState(false)
  const stories1 = home_grid_1.map(el => articleNormalizer(el.grid_article))
  const stories2 = home_grid_2.map(el => articleNormalizer(el.grid_article))
  const stories3 = home_grid_3.map(el => articleNormalizer(el.grid_article))
  const additional = (additional_home_grids || []).map(el => {
    el.home_grid = el.home_grid.map(e => articleNormalizer(e.grid_article))
    return el
  }).filter(el => el.home_grid.length > 0)
  
  function onHeroBgChange(bgColor) {
    setHeaderDark(bgColor === "light")
  }

  return (
    <>
      <SEO title="ArtReview" />
      <Ad type="homepage/before_header" />
      <PageWrapper>
        <Header dark={headerDark} scaleLogo={true} />
        <SlideshowHero onBgChange={onHeroBgChange} />
        <Ad type="homepage/before_grid_1" />
        {home_grid_1_show && (
          <SectionStoryGrid layout="homepage-1" items={stories1} />
        )}
        <SectionSlider source="social-media" />
        <Ad type="homepage/before_grid_2" />
        {home_grid_2_show && (
          <SectionStoryGrid layout="homepage-2" items={stories2} />
        )}
        <SectionSubscribe />
        {home_grid_3_show && (
          <SectionStoryGrid layout="homepage-3" items={stories3} />
        )}
        <SectionSlider source="archive" title="Archive" />
        {additional.map((grid, i) => (
          <>
            {i > 0 && <SectionSubscribe />}
            <SectionStoryGrid layout={`homepage`} items={grid.home_grid} />
            </>
        ))}
      </PageWrapper>
      <Ad type="homepage/before_footer" />
    </>
  )
}
