const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => (m && m.default) || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/.cache/dev-404-page.js"))),
  "component---src-pages-index-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/pages/index.js"))),
  "component---src-pages-preview-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/pages/preview.js"))),
  "component---src-pages-search-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/pages/search.js"))),
  "component---src-pages-shop-product-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/pages/shop/product.js"))),
  "component---src-templates-article-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/article.js"))),
  "component---src-templates-artist-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/artist.js"))),
  "component---src-templates-author-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/author.js"))),
  "component---src-templates-category-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/category.js"))),
  "component---src-templates-job-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/job.js"))),
  "component---src-templates-page-careers-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/pageCareers.js"))),
  "component---src-templates-page-full-width-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/pageFullWidth.js"))),
  "component---src-templates-page-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/page.js"))),
  "component---src-templates-page-power-100-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/pagePower100.js"))),
  "component---src-templates-page-subscribe-js": hot(preferDefault(require("/var/deployment/releases/20241129090111/src/templates/pageSubscribe.js")))
}

